import axios from "axios";

const urlBase =
  "https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-mobile";
const urlBaseFiles =
  "https://gateway-admin-plus.sistemasinteegra.com.br/api-admin-files";
const urlBaseQrcodeReader = "https://leitor-qrcode-api.sistemasinteegra.com.br";

function getFunctions(participanteId, eventId) {
  var config = {
    method: "get",
    url: `${urlBase}/functions/login/${participanteId}/event/${eventId}/released`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function readFunctions(participanteId, functionId, eventId) {
  var data = JSON.stringify({
    participanteId,
    functionId,
    eventId,
  });
  var config = {
    method: "post",
    url: `${urlBase}/functions/release`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function postDownload(eventId, loginId, fileId, fileName) {
  var data = JSON.stringify({
    eventId,
    loginId,
    fileId,
    fileName,
  });

  var config = {
    method: "post",
    url: `${urlBaseFiles}/download`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getConfigQrCode(idEvent) {
  try {
    const config = {
      method: "get",
      url: `${urlBaseQrcodeReader}/qrreadersetup/${idEvent}/event`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function getListQrCode(idEvent) {
  try {
    const config = {
      method: "get",
      url: `${urlBaseQrcodeReader}/qrcode/${idEvent}/event`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function getListQrCodeRead(idEvent, loginId) {
  try {
    const config = {
      method: "get",
      url: `${urlBaseQrcodeReader}/qrcode/${idEvent}/event/${loginId}/login?offset=1&limit=200`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

async function functionLog(data) {
  try {
    const config = {
      method: "post",
      url: `${urlBaseQrcodeReader}/functionlog`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    // console.log(error.response);
    return error.response;
  }
}

export {
  getFunctions,
  readFunctions,
  postDownload,
  getConfigQrCode,
  getListQrCode,
  getListQrCodeRead,
  functionLog,
};
