export const codeList = [
  { id: "99c367d8-eff5-417e-b66d-8b6228cc9a56", description: "QRCODEPREMIADO" },
  {
    id: "572d4675-6f91-44eb-a0b2-4d97814a3d27",
    description: "QRCODEPREMIADO2",
  },
  {
    id: "100314b9-b16f-438f-821b-c02b0149be15",
    description: "QRCODEPREMIADO3",
  },
  {
    id: "900808ff-34d6-483e-adb7-bd1619223fcb",
    description: "QRCODEPREMIADO4",
  },
  {
    id: "ec5e59f3-ac3c-4d47-a4bd-4c15495c4228",
    description: "QRCODEPREMIADO5",
  },
  {
    id: "bce7f840-cb11-4694-8c11-92126945498a",
    description: "FIVETRANSPORTES",
  },
  { id: "99db366e-d97d-4463-b31b-b9ceac4f02cf", description: "WTC" },
  { id: "5c515138-69b4-4fb0-9f5a-7a540f91dd1e", description: "Extra3" },
  { id: "fefe337f-0100-4461-bf66-2ccfcae6c7e0", description: "IDEEEVENTOS" },
  { id: "db9e0863-7898-40c4-ad59-a79e220db322", description: "UNIGLOBE" },
  { id: "23a2772c-7397-4413-ba04-6c9b45fa0b85", description: "BLUETREE" },
  { id: "23085d75-de88-4ae2-8391-e331f82c25a1", description: "CEPTRANSPORTES" },
  { id: "debbd1cc-816b-4307-8aa9-2194e06c73e1", description: "MIAMI" },
  { id: "1ee3ac2a-c01c-4962-b441-94be9c3aa402", description: "R1" },
  { id: "8745b949-6886-4b81-a4e8-35adc4f9dca9", description: "TGMOB" },
  { id: "5dfe0438-96d2-4e29-b20e-5b272cc6e75a", description: "B2BMASTERCARD" },
  { id: "b15b34af-a2a8-41bf-9057-877a5031b44b", description: "GOLDENTULIP" },
  { id: "aa374ef0-516c-4d70-9c29-b0904d349aa6", description: "COSTABRAVA" },
  { id: "369da959-72d3-4328-b972-c6a169d76bf4", description: "PAYTRACK" },
  { id: "5bae8fa3-08da-4b1d-a6ef-2d0f5280972e", description: "UBER" },
  { id: "8577d172-9df2-46c8-b979-47491616c058", description: "GOL" },
  { id: "496985bd-f4d7-4a04-a354-339be36be364", description: "TPCORPORATE" },
  { id: "10975cb9-d84a-4d75-b770-4b5fabd30547", description: "IBERIA" },
  { id: "e54d2f7e-52a6-486e-aaec-68de5a55d39a", description: "MARINGA" },
  { id: "a844d2a8-b4a3-47ab-a3ba-7a1de48832b4", description: "Extra4" },
  { id: "8353ce37-a79f-49b1-9df6-fc171a17edfd", description: "CHARLIE" },
  { id: "0c358ec7-e9dd-4782-8529-efd5ed39fd58", description: "AIRALO" },
  { id: "9c4c1759-258c-46f4-b9c7-fcadd1c5f503", description: "Extra10" },
  { id: "52d0a461-a900-4766-98eb-dc927ff5872b", description: "Extra8" },
  { id: "ec8704cd-714b-4618-b6e9-a058ab90bfa4", description: "Extra7" },
  { id: "00b2b64c-a5c6-4a85-8dac-e2aa32de43be", description: "MONTANARI" },
  { id: "e032c715-ae97-4bea-98cd-faebf9eaaa7f", description: "NYC" },
  { id: "36366ead-1e8c-4763-bb8a-fe3104958c5b", description: "BEFLY" },
  { id: "b91cd3ca-9ae3-410a-b2d4-d35a4be4ac9b", description: "ORICASTING" },
  { id: "80d8db01-969d-4581-954a-227c705f1145", description: "SHIFT" },
  { id: "4f0b09b4-9295-45a0-924b-ae55988d4a6b", description: "LCAVIAGENS" },
  { id: "fa1446a9-8c87-4d2e-ae0e-14c85a3e634f", description: "ONFLY" },
  { id: "b59732cf-3418-4bb1-89db-59b4c9f3df0f", description: "ATLANTICA" },
  { id: "8c015806-b5d6-4dcd-a637-44be33af6328", description: "INTERCITY" },
  { id: "5ea0795d-bdab-4069-adbe-a829222ac2c3", description: "INTEEGRA" },
  { id: "5aa951d7-00f2-4895-91e5-4faaa9a97373", description: "COPASTUR" },
  { id: "5b2b0235-5216-4d53-bc10-a04ee32ab8eb", description: "ROYALPALMPLAZA" },
  { id: "2c382c45-ccef-4ca3-9a9e-1c445e4390be", description: "TIVOLI" },
  { id: "32cdeea5-a43a-4028-be98-d90e0ad5eaac", description: "VOLL" },
  { id: "619e8080-cb8d-4540-b08d-89a21be0b8e9", description: "FCMKONTIK" },
  { id: "e2244553-80c8-476c-a2fc-21e36adf1e3b", description: "IHG" },
  { id: "e267f9c1-476d-454b-9533-318a5a8c69aa", description: "KVCONTEUDO" },
  { id: "f8471da6-8acd-40b6-814b-1da82032be3d", description: "KALUAHTOURS" },
  { id: "d4261ff7-2ce4-44ad-9e0a-cdedbf305223", description: "ACCOR" },
  { id: "a85373f4-2b6d-4726-b6cb-301cbc761205", description: "DUBAI" },
  { id: "11ee8aee-c112-4852-90ff-55283849decb", description: "Extra1" },
  { id: "ac685785-c770-491d-a0aa-9282d239df23", description: "LATAMDELTA" },
  { id: "75d99a53-e374-4f4e-bec9-edb2d1bdd1a1", description: "AZUL" },
  { id: "428446fe-d04d-4f16-b6c1-7dfc13a8af12", description: "SWAP" },
  { id: "b4d3e617-35ac-44e5-bd45-901e4f203598", description: "EMPROTUR" },
  { id: "8bd5c997-807f-4a24-a7ab-8a6487c2b008", description: "BRADESCO" },
  { id: "962cc2c3-398e-45c7-8380-8718f83fbe44", description: "AVIVA" },
  { id: "4c519feb-9fe7-4063-a86c-58ab3dd7e67d", description: "Extra5" },
  { id: "560a336d-3a0c-4c62-9d43-d4baedee0937", description: "HILTON" },
  { id: "715f7dda-1051-45a9-99fb-72bc5541f7fb", description: "VOETUR" },
  { id: "6e6979dd-58f9-40b9-b567-e82e0844bd22", description: "Extra9" },
  { id: "a0ee58ef-c139-47b2-aa61-607c7e13ba25", description: "Extra2" },
  { id: "a14b3ea0-8ae8-442c-8f8b-626aaf43f0da", description: "Extra6" },
];
