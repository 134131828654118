import React, { useState, useEffect } from "react";
import "./styled.css";
// qrcode
import Qreader from "react-qr-reader";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// img
import Logo from "../../assets/image/icon.png";
// componentes
import ListQrcode from "./listQrcode";
// service
import { functionLog } from "../../services/qrCode";
import { createEarnedPoints } from "../../services/ranking";

export default function ReadQrcode(props) {
  const { colorBtn, imgLogo, listCodesRead, user, getListRead, listAllCodes } =
    props;
  // leitor
  const [scan, setScan] = useState(false);
  // alertas
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [error, setError] = useState("");
  // modal
  const [listQrcode, setListQrcode] = useState(false);
  // carregamento
  const [loading, setLoading] = useState(false);
  // todos qrcode ja lidos
  const [fullQrcodes, setFullQrcodes] = useState(false);

  async function readScan(e) {
    setLoading(true);

    const search = listAllCodes.find((code) => code.id === e);

    if (!search) {
      setError("O QR Code lido não é válido");
      setLoading(false);
      return;
    } else {
      if (search.read === "sim") {
        setError("Esse QRCode já foi usado!");
        return;
      }

      try {
        changeScan();

        const dataRanking = {
          code: search.type,
          positive: true,
          log: "add points qrcode",
        };

        const res = await createEarnedPoints(
          dataRanking,
          user.idEvent,
          user.idPart
        );

        if (res.message === "ADDED_TO_QUEUE") {
          const dataCheck = {
            eventId: user.idEvent,
            loginId: user.idPart,
            functionId: search.id,
          };

          const resCheck = await functionLog(dataCheck);

          if (resCheck.data.message === "CREATED") {
            setQrcodeRead(true);
          } else if (resCheck.data.message === "FUNCTION_YET_RELEASED") {
            setError("Esse QRCode já foi usado!");
          } else {
            setError("Erro ao ler o QR Code, tente novamente em breve");
          }
        }
      } catch (error) {
        setError("Erro ao pontuar, tente novamente em breve");
      } finally {
        setLoading(false);
      }
    }
  }

  function changeScan() {
    // transform: rotateY(90deg);
    const div = document.getElementById("card-id");

    div.style.transform = "rotateY(90deg)";

    setTimeout(() => {
      setScan(!scan);
      div.style.transform = "rotateY(0)";
    }, 400);
  }

  useEffect(() => {
    if (listCodesRead && !listCodesRead.find((e) => e.read === "nao")) {
      setFullQrcodes(true);
      return;
    } else setFullQrcodes(false);
  }, [listCodesRead]);

  return (
    <>
      {qrcodeRead && (
        <SweetAlert
          success
          title="Pontos atribuidos"
          onConfirm={() => {
            getListRead();
            setQrcodeRead(false);
            setScan(false);
          }}
        >
          Pontos atribuídos ao participante!
        </SweetAlert>
      )}

      {error && (
        <SweetAlert
          warning
          title="Alerta"
          onConfirm={() => {
            setError("");
          }}
        >
          {error}
        </SweetAlert>
      )}

      {listQrcode && (
        <ListQrcode
          isOpen={listQrcode}
          toggle={() => setListQrcode(false)}
          listCodesRead={listCodesRead}
        />
      )}

      <div className="card-general-qrcode" id="card-id">
        {scan ? (
          <Qreader
            onScan={(e) => {
              if (e && scan && !loading) {
                readScan(e);
              }
            }}
            className="qrcode-scan"
          />
        ) : (
          <img
            src={imgLogo ? imgLogo : Logo}
            style={{
              width: 280,
              height: 280,
              borderRadius: "50%",
            }}
          />
        )}
      </div>

      {fullQrcodes ? (
        <>
          <div>
            <h3 className="qrcode-msg" style={{ textAlign: "center" }}>
              Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!
            </h3>
          </div>
        </>
      ) : (
        <>
          {scan ? (
            <a
              className="action-reader close-reader"
              onClick={() => changeScan()}
            >
              Fechar Scan
            </a>
          ) : (
            <>
              <a
                className="action-reader open-reader"
                style={{ backgroundColor: colorBtn }}
                onClick={() => changeScan()}
              >
                Ler QRCode
              </a>

              <a
                className="action-reader open-reader"
                style={{ backgroundColor: colorBtn }}
                onClick={() => setListQrcode(true)}
              >
                Lista de QRCodes
              </a>
            </>
          )}
        </>
      )}
    </>
  );
}
