import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import ReadQrcode from "./readQrcode";

export default function QrCodeAlagev() {
  // parametros
  const [searchParams] = useSearchParams();
  // usuario
  const [user, setUser] = useState(null);
  // carregamento
  const [loading, setLoading] = useState(true);
  // cores
  const colorBg = "#244D75";
  const colorBtn = "#A4E300";

  useEffect(() => {
    if (window.location.search !== "") {
      const idPart = searchParams.get("idPart");
      const idEvent = searchParams.get("idEvent");

      setUser({ idPart, idEvent });
    }
  }, [searchParams]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="div-general-qrcode" style={{ backgroundColor: colorBg }}>
      {loading ? (
        <a className="a-loading" />
      ) : (
        <>
          <ReadQrcode colorBtn={colorBtn} user={user} />
        </>
      )}
    </div>
  );
}
