import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import ReadQrcode from "./readQrcode";
// service
import {
  getConfigQrCode,
  getListQrCode,
  getListQrCodeRead,
} from "../../services/qrCode";

export default function Qrcode() {
  // parametros
  const [searchParams] = useSearchParams();
  // usuario
  const [user, setUser] = useState(null);
  // carregamento
  const [loading, setLoading] = useState(true);
  // config
  const [colorBg, setColorBg] = useState("#fffff");
  const [colorBtn, setColorBtn] = useState("#0f20db");
  const [imgLogo, setImgLogo] = useState(null);
  const [listCodesRead, setlistCodesRead] = useState([]);
  const [listAllCodes, setlistAllCodes] = useState([]);
  // erros
  const [errors, setErrors] = useState("");

  async function getConfig() {
    try {
      const res = await getConfigQrCode(user.idEvent);

      if (res?.data?.total && res.data.total > 0) {
        const data = res.data.data[0];

        setColorBg(data.backgroundColor);
        setColorBtn(data.buttonColor);
        setImgLogo(data.image);

        getListRead();
        getList();
      } else setErrors("Evento sem configuração");
    } catch (error) {
      console.log("erro", error);
      setErrors(
        "Erro na requisição dos dados da página, tente novamente em breve"
      );
    } finally {
      setLoading(false);
    }
  }

  async function getListRead() {
    try {
      const res = await getListQrCodeRead(user.idEvent, user.idPart);

      if (res?.data?.total && res.data.total > 0) {
        setlistCodesRead(res.data.data);
      }
    } catch (error) {
      console.log("list erro", error);
    }
  }

  async function getList() {
    try {
      const res = await getListQrCode(user.idEvent);

      if (res?.data?.total && res.data.total > 0) {
        setlistAllCodes(res.data.data);
      }
    } catch (error) {
      console.log("list erro", error);
    }
  }

  useEffect(() => {
    if (window.location.search !== "") {
      const idPart = searchParams.get("idPart");
      const idEvent = searchParams.get("idEvent");

      setUser({
        idPart,
        idEvent,
      });
    } else setErrors("Erro ao ler participante e/ou evento");

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [searchParams]);

  useEffect(() => {
    if (user) getConfig();
  }, [user]);

  return (
    <div className="div-general-qrcode" style={{ backgroundColor: colorBg }}>
      {loading ? (
        <a className="a-loading" />
      ) : errors ? (
        <div className="div-error">
          <h4>{errors}</h4>
        </div>
      ) : (
        <ReadQrcode
          colorBtn={colorBtn}
          imgLogo={imgLogo}
          listCodesRead={listCodesRead}
          listAllCodes={listAllCodes}
          user={user}
          getListRead={getListRead}
        />
      )}
    </div>
  );
}
