import React from "react";
import "./styled.css";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function ListQrcode(props) {
  const { isOpen, toggle, listCodesRead } = props;

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Lista de QRCodes</ModalHeader>
        <ModalBody>
          {listCodesRead &&
            listCodesRead.length > 0 &&
            listCodesRead.map((e, i) => (
              <div
                className="div-option-qrcode"
                style={{
                  backgroundColor: e.read === "sim" ? "#1dd71d" : "#d1d1d1",
                }}
                onClick={() => console.log(e)}
              >
                <a>{e.description}</a>
              </div>
            ))}
        </ModalBody>
      </Modal>
    </>
  );
}
